.elementor-21477 .elementor-element.elementor-element-a98c644 {
    --display: flex;
    --flex-direction: column;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --background-transition: 0.3s;
    --margin-block-start: 6rem;
    --margin-block-end: 0rem;
    --margin-inline-start: 0rem;
    --margin-inline-end: 0rem
  }
  
  body.elementor-page-21477:not(.elementor-motion-effects-element-type-background),
  body.elementor-page-21477>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: #e7f9ff
  }
  
  @media(max-width:1024px) {
    .elementor-21477 .elementor-element.elementor-element-6ff1770 .elementor-heading-title {
      font-size: 2.5rem
    }
  }
  
  @media(max-width:767px) {
    .elementor-21477 .elementor-element.elementor-element-a98c644 {
      --padding-block-start: 1rem;
      --padding-block-end: 1rem;
      --padding-inline-start: 1rem;
      --padding-inline-end: 1rem
    }
  
    .elementor-21477 .elementor-element.elementor-element-6ff1770 .elementor-heading-title {
      letter-spacing: -2px
    }
  }
  
  .elementor-21477 .elementor-element.elementor-element-a98c644 h2,
  .elementor-21477 .elementor-element.elementor-element-a98c644 h3 {
    color: #13487c !important
  }