/*! elementor - v3.19.0 - 07-02-2024 */
.elementor-widget-divider {
    --divider-border-style: none;
    --divider-border-width: 1px;
    --divider-color: #0c0d0e;
    --divider-icon-size: 20px;
    --divider-element-spacing: 10px;
    --divider-pattern-height: 24px;
    --divider-pattern-size: 20px;
    --divider-pattern-url: none;
    --divider-pattern-repeat: repeat-x
}

.elementor-widget-divider .elementor-divider {
    display: flex
}

.elementor-widget-divider .elementor-divider__text {
    font-size: 15px;
    line-height: 1;
    max-width: 95%;
}

.elementor-widget-divider .elementor-divider__element {
    margin: 0 var(--divider-element-spacing);
    flex-shrink: 0;
}

.elementor-widget-divider .elementor-icon {
    font-size: var(--divider-icon-size);
}

.elementor-widget-divider .elementor-divider-separator {
    display: flex;
    margin: 0;
    direction: ltr
}

.elementor-widget-divider--view-line_icon .elementor-divider-separator,
.elementor-widget-divider--view-line_text .elementor-divider-separator {
    align-items: center
}

.elementor-widget-divider--view-line_icon .elementor-divider-separator:after,
.elementor-widget-divider--view-line_icon .elementor-divider-separator:before,
.elementor-widget-divider--view-line_text .elementor-divider-separator:after,
.elementor-widget-divider--view-line_text .elementor-divider-separator:before {
    display: block;
    content: "";
    border-block-end: 0;
    flex-grow: 1;
    border-block-start: var(--divider-border-width) var(--divider-border-style) var(--divider-color)
}

.elementor-widget-divider--element-align-left .elementor-divider .elementor-divider-separator>.elementor-divider__svg:first-of-type {
    flex-grow: 0;
    flex-shrink: 100;
}

.elementor-widget-divider--element-align-left .elementor-divider-separator:before {
    content: none
}

.elementor-widget-divider--element-align-left .elementor-divider__element {
    margin-left: 0
}

.elementor-widget-divider--element-align-right .elementor-divider .elementor-divider-separator>.elementor-divider__svg:last-of-type {
    flex-grow: 0;
    flex-shrink: 100;
}

.elementor-widget-divider--element-align-right .elementor-divider-separator:after {
    content: none;
}

.elementor-widget-divider--element-align-right .elementor-divider__element {
    margin-right: 0;
}

.elementor-widget-divider--element-align-start .elementor-divider .elementor-divider-separator>.elementor-divider__svg:first-of-type {
    flex-grow: 0;
    flex-shrink: 100;
}

.elementor-widget-divider--element-align-start .elementor-divider-separator:before {
    content: none;
}

.elementor-widget-divider--element-align-start .elementor-divider__element {
    margin-inline-start: 0;
}

.elementor-widget-divider--element-align-end .elementor-divider .elementor-divider-separator>.elementor-divider__svg:last-of-type {
    flex-grow: 0;
    flex-shrink: 100;
}

.elementor-widget-divider--element-align-end .elementor-divider-separator:after {
    content: none
}

.elementor-widget-divider--element-align-end .elementor-divider__element {
    margin-inline-end: 0
}

.elementor-widget-divider:not(.elementor-widget-divider--view-line_text):not(.elementor-widget-divider--view-line_icon) .elementor-divider-separator {
    border-block-start: var(--divider-border-width) var(--divider-border-style) var(--divider-color)
}

.elementor-widget-divider--separator-type-pattern {
    --divider-border-style: none
}

.elementor-widget-divider--separator-type-pattern.elementor-widget-divider--view-line .elementor-divider-separator,
.elementor-widget-divider--separator-type-pattern:not(.elementor-widget-divider--view-line) .elementor-divider-separator:after,
.elementor-widget-divider--separator-type-pattern:not(.elementor-widget-divider--view-line) .elementor-divider-separator:before,
.elementor-widget-divider--separator-type-pattern:not([class*=elementor-widget-divider--view]) .elementor-divider-separator {
    width: 100%;
    min-height: var(--divider-pattern-height);
    -webkit-mask-size: var(--divider-pattern-size) 100%;
    mask-size: var(--divider-pattern-size) 100%;
    -webkit-mask-repeat: var(--divider-pattern-repeat);
    mask-repeat: var(--divider-pattern-repeat);
    background-color: var(--divider-color);
    -webkit-mask-image: var(--divider-pattern-url);
    mask-image: var(--divider-pattern-url)
}

.elementor-widget-divider--no-spacing {
    --divider-pattern-size: auto
}

.elementor-widget-divider--bg-round {
    --divider-pattern-repeat: round
}

.rtl .elementor-widget-divider .elementor-divider__text {
    direction: rtl
}

.e-con-inner>.elementor-widget-divider,
.e-con>.elementor-widget-divider {
    width: var(--container-widget-width, 100%);
    --flex-grow: var(--container-widget-flex-grow)
}

.image-container {
    position: relative;
    width: 500px;
    height: 500px;
    /*overflow: hidden;*/
}

.slide-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 2s, transform 2s;
}

.slide-image img {
    -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(70%, transparent), to(rgba(250, 250, 250, 0.2)))
}

.slide-image p {
    text-align: center;
}

.fade-in {
    opacity: 1;
    transform: translateX(0);
}

.fade-out {
    opacity: 0;
    transform: translateX(-100%);
}



/* body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
}*/

/* #image1:after {
	content: "";
	position: absolute;
	background-image: url("/wp-content/uploads/2023/08/cab-eddie.png");
display: block;
	width: inherit;
	height: 40%;
	bottom: -41%;
	transform: scaleY(-1);
	-webkit-mask-image:-webkit-gradient(linear, left 50%, left bottom, from(rgba(0,0,0,0)), to(rgba(0,0,0,.5)));
} */

/*! elementor - v3.19.0 - 07-02-2024 */
.elementor-image-gallery .gallery-item {
    display: inline-block;
    text-align: center;
    vertical-align: top;
    width: 100%;
    max-width: 100%;
    margin: 0 auto
}

.elementor-image-gallery .gallery-item img {
    margin: 0 auto
}

.elementor-image-gallery .gallery-item .gallery-caption {
    margin: 0
}

.elementor-image-gallery figure img {
    display: block
}

.elementor-image-gallery figure figcaption {
    width: 100%
}

.gallery-spacing-custom .elementor-image-gallery .gallery-icon {
    padding: 0
}

@media (min-width:768px) {
    .elementor-image-gallery .gallery-columns-2 .gallery-item {
        max-width: 50%
    }

    .elementor-image-gallery .gallery-columns-3 .gallery-item {
        max-width: 33.33%
    }

    .elementor-image-gallery .gallery-columns-4 .gallery-item {
        max-width: 25%
    }

    .elementor-image-gallery .gallery-columns-5 .gallery-item {
        max-width: 20%
    }

    .elementor-image-gallery .gallery-columns-6 .gallery-item {
        max-width: 16.666%
    }

    .elementor-image-gallery .gallery-columns-7 .gallery-item {
        max-width: 14.28%
    }

    .elementor-image-gallery .gallery-columns-8 .gallery-item {
        max-width: 12.5%
    }

    .elementor-image-gallery .gallery-columns-9 .gallery-item {
        max-width: 11.11%
    }

    .elementor-image-gallery .gallery-columns-10 .gallery-item {
        max-width: 10%
    }
}

@media (min-width:480px) and (max-width:767px) {

    .elementor-image-gallery .gallery.gallery-columns-2 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-3 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-4 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-5 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-6 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-7 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-8 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-9 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-10 .gallery-item {
        max-width: 50%
    }
}

@media (max-width:479px) {

    .elementor-image-gallery .gallery.gallery-columns-2 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-3 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-4 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-5 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-6 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-7 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-8 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-9 .gallery-item,
    .elementor-image-gallery .gallery.gallery-columns-10 .gallery-item {
        max-width: 100%
    }
}