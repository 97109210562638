/* @import url('https://edyou.com/wp-content/plugins/anywhere-elementor-pro-2/includes/assets/css/ae-pro.min.css?ver=2.26.2'); */
/* @import url('https://edyou.com/wp-content/plugins/anywhere-elementor-pro-2/includes/assets/lib/vegas/vegas.min.css?ver=2.4.0'); */
/* @import url('https://edyou.com/wp-content/plugins/metform/public/assets/css/text-editor.css?ver=3.8.3'); */
/* @import '../node_modules/bootstrap/dist/css/bootstrap.min.css'; */
@import './assets/css/main.css';
@import './assets/css/footer.css';
@import './assets/css/terms-privacy-21477-singlepage.css';
/* @import './assets/css/tems-privacy-21477-singlepage.css; */
/* @import './assets/css/navigation-bar.css'; */
/* @import './assets/css/welcome.css'; */



.tg-dialog-btn {
    padding: 5px 10px;
    border: none !important;
    background: none !important;
    color: #2A7CC7 !important;
    border-radius: 4px;
    font-weight: 500;
    font-size: .82rem;
    white-space: nowrap;
  }
  
   .tg-dialog-body {
    padding: 10px !important;
    color: #3C403D;
    font-size: 14px;
    margin-left: 10px !important;
    font-family: DM Sans;
    font-weight: 400 !important;
    line-height: 27px;
    letter-spacing: 0.20px;
    word-wrap: break-word;
    /* font-weight: 400; */
  }
  
  
   .tg-dialog-close-btn {
    width: 12px;
    height: 12px;
    margin-left: 10px;
    opacity: .4;
    cursor: pointer;
    margin-top: -20px !important;
    white-space: nowrap;
  }

  .ppp{
    z-index: 2147483647 !important;
  }


img.wp-smiley,
img.emoji {
    display: inline !important;
    border: none !important;
    box-shadow: none !important;
    height: 1em !important;
    width: 1em !important;
    margin: 0 0.07em !important;
    vertical-align: -0.1em !important;
    background: none !important;
    padding: 0 !important;
}


.lg\:visible {
  visibility: visible !important;
}


.order-1 {
  -ms-flex-order: 1;
  order: 0 !important;
}


.mx-2 {
  margin-right: none !important;
}

.ml-2, .mx-2 {
  margin-left: none !important;
}

@media (min-width: 1280px) {
  .xl\:mr-\[24px\] {
      margin-right: 24px !important;
  }
}

@media (min-width: 1280px) {
  .xl\:mx-\[14px\] {
      margin-left: 14px !important;
      margin-right: 14px !important;
  }
}

@media (min-width: 1024px) {
  .lg\:my-\[18px\] {
      /* margin-bottom: 18px !important; */
      margin-top: 18px !important;
  }
}

.lg\:my-\[18px\] {
  /* margin-bottom: 18px !important; */
  margin-top: 18px !important;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
  /* display: block !important;  */

}

/* =========== */

._slide__content-button-link_186yt_807 {
    background-color: #eee6da;
    border-radius: .25rem;
    border: none;;
    color: #62554a;
    display:block !important;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .5px;
    line-height: 1.125rem;
    text-align: center;
 width: 80%;
    z-index: 2;
}

._home-tout__item-button-link_dbuct_439 {
    background-color: #eee6da;
    border-radius: .25rem;
    border: none;
    color: #62554a;
    display: block !important;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .5px;
    line-height: 1;
    /* padding: .9375rem; */
    text-align: center;
    width: 80%;
    z-index: 2;
}

/* .currentColor{
  background-color: #62554A;
} */


.links {
  position: fixed;
  top: 1rem;
  right: 1rem;
  color: yellow;

}
a {
  color: yellow;
  font-family: Arial;
}


.classBLock {
  margin: 0 !important;
  padding: 0 !important;
  display: block !important;
  height: 100vh;
}


.imgBlock {
  width: 100%;
  height: 100vh;
}